import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import HeaderCongress from "../components/headerCongress"
import "../styles/landing-campaign.scss"
import "../styles/securityCongress.scss"
import "../styles/contacto.scss"
import { IconContext } from "react-icons"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { Link } from "gatsby"

export default function CyberSummit(props) {
  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [mensaje, setMensaje] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [mensajeError, setMensajeError] = useState("")
  const [respuesta, setRespuesta] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("leadsciberresiliency")
    //--> grab the generated token by the reCAPTCHA
    // Prepare the data for the server, specifically body-parser

    const data = JSON.stringify({ nombre, email, telefono, mensaje, result })
    // POST request to your server

    fetch(`${process.env.API_URL}/securitycongress-2023-s`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then(res => res.json())
      .then(data => {
        const success = data.success
        const score = data.score

        if (success && score >= 0.7) {
          setMensajeError("Gracias por ponerte en contacto con nosotros")
          setRespuesta("correcto")
          setNombre("")
          setEmail("")
          setTelefono("")
          setMensaje("")
          setIsChecked(false)
        } else {
          setMensajeError(
            "Ha habido un error en el envío del formulario, por favor inténtalo de nuevo"
          )
          setRespuesta("error")
        }
      })
  }

  return (
    <Layout>
      <Seo
        title="bullhost cyber resillinecy summit"
        pathname={props.location.pathname}
        robots="index,follow"
        imagen={`${props.location.origin}/img/landings/cyber-resiliency-summit/hero.webp`}
      />

      <div className="cabecera-fixed">
        <HeaderCongress
          titulo="Cyber resiliency summit"
          imagen="/img/landings/cyber-resiliency-summit/hero-sin-texto.webp"
        />
        <div className="intro-campaign congress">
          <img src="/img/bullhost-white.svg" alt="Logo de Bullhost"></img>
          <img
            src="/img/logos-fabricantes/paloalto-logo.png"
            alt="Logo de Paloalto"
          ></img>
          <img
            src="/img/landings/security-congress/EXN.PA_BIG.png"
            alt="Logo de Exclusive Networks"
          ></img>
          <img
            src="/img/logos-fabricantes/veeam-logo.png"
            alt="Logo de EM Masaló"
          ></img>
        </div>
      </div>
      <div className="congress">
        <div className="cuerpo-servicio" style={{ overflow: "hidden" }}>
          {/*BLOQUE TEXTO*/}
          <section className="limitador contenido__servicio pad-congress">
            <div id="grid-entradas">
              <div className="row">
                <h2>
                  De la protección a la recuperación: El camino de la ciber
                  resiliencia.
                </h2>
                <p>
                  Aprende sobre nuestra solución integral de ciberseguridad,
                  diseñada para proteger tus activos digitales en un mundo en
                  constante evolución.
                </p>
                <p>
                  <b>El día 7 de noviembre</b> exploraremos las últimas
                  tendencias en protección perimetral y cómo pueden salvaguardar
                  tu red de las amenazas digitales, evolucionado hacia un
                  enfoque basado en el estándar NIST, exploraremos las fases de
                  respuesta y recuperación, trataremos conceptos clave como el
                  Zero Trust, inmutabilidad y disponibilidad de datos.
                </p>
                <p>
                  Descubre cómo podemos garantizar la seguridad de tus datos en
                  todo momento.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Reserva ya tu lugar en el bullhost Cyber resiliency summit y
                  consigue el conocimiento de mayor valor de los últimos años.
                </p>
              </div>
            </div>
          </section>
          {/* FIN BLOQUE TEXTO */}
          {/*BLOQUE FOTO*/}
          <section className="limitador contenido__servicio foto-mujer pad-congress">
            <div id="grid-entradas">
              <div className="row">
                <img
                  src="/img/landings/security-congress/empresaria-mirando-presentacion-interactiva.webp"
                  alt="Empresaria mirando una presentación interactiva"
                ></img>
                <h2>
                  Protege tu empresa contra ciberamenazas: descubre las
                  soluciones más avanzadas en ciberseguridad
                </h2>
              </div>
            </div>
          </section>
          {/* FIN BLOQUE FOTO */}
          {/*BLOQUE HORARIO*/}
          <section className="bloque-horario">
            <div className="blur">
              <div className="ellipse horario"></div>
            </div>
            <div className="header-inicio limitador-inicio">
              <h2>Agenda del evento</h2>
            </div>
            <section className="limitador contenido__servicio congress">
              <div className="prueba-demo">
                <div className="cuerpo-contacto bloque-noasistir-div congress">
                  <div className="caja-formulario congress">
                    <div className="tabla-horario">
                      <div className="tabla-horario_row">
                        <span className="hora">Horario</span>
                      </div>
                      <div className="tabla-horario_row">
                        <span className="hora">10:30 · </span>
                        <div className="descripcion">
                          <span className="actividad">
                            Apertura, presentación del evento
                          </span>
                          <span className="ponente">| bullhost</span>
                        </div>
                      </div>
                      <div className="tabla-horario_row">
                        <span className="hora">10:45 · </span>
                        <div className="descripcion">
                          <span className="actividad">
                            "Protegiendo tus activos digitales"
                          </span>
                          <span className="ponente">| bullhost</span>
                        </div>
                      </div>
                      <div className="tabla-horario_row">
                        <span className="hora">11:15 · </span>
                        <div className="descripcion">
                          <span className="actividad">
                            "Protección perimetral"
                          </span>
                          <span className="ponente">| Palo Alto Networks</span>
                        </div>
                      </div>
                      <div className="tabla-horario_row">
                        <span className="hora">11:45 · </span>
                        <div className="descripcion">
                          <span className="actividad">
                            "Unificando estrategia"
                          </span>
                          <span className="ponente">| Exclusive Networks</span>
                        </div>
                      </div>
                      <div className="tabla-horario_row">
                        <span className="hora">12:15 · </span>
                        <div className="descripcion">
                          <span className="actividad">Coffee Break</span>
                        </div>
                      </div>
                      <div className="tabla-horario_row">
                        <span className="hora">12:45 · </span>
                        <div className="descripcion">
                          <span className="actividad">
                            "Asegurando la recuperación"
                          </span>
                          <span className="ponente">| Veeam</span>
                        </div>
                      </div>
                      <div className="tabla-horario_row">
                        <span className="hora">13:15 · </span>
                        <div className="descripcion">
                          <span className="actividad">
                            "Business Continuity Model by Bullhost"
                          </span>
                          <span className="ponente">| bullhost</span>
                        </div>
                      </div>
                      <div className="tabla-horario_row">
                        <span className="hora">13:45 · </span>
                        <div className="descripcion">
                          <span className="actividad">Cóctel | Networking</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          {/*FIN BLOQUE HORARIO*/}

          {/* SECCION FORMULARIO */}
          <section className="limitador contenido__servicio">
            <div className="blur">
              <div className="ellipse contacto"></div>
            </div>
            <h2>¿Quieres acudir?</h2>
            <div className="bloque-noasistir">
              <div className="prueba-demo">
                <div className="cuerpo-contacto bloque-noasistir-div congress">
                  <div className="caja-formulario">
                    <h3>Quiero apuntarme</h3>
                    <form
                      className="form-contacto"
                      method="post"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <label htmlFor="nombre">Nombre</label>
                      <input
                        name="nombre"
                        placeholder="Nombre*"
                        required
                        value={nombre}
                        onChange={e => setNombre(e.target.value)}
                      />

                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email*"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />

                      <label htmlFor="telefono">Teléfono</label>
                      <input
                        type="tel"
                        name="telefono"
                        placeholder="Teléfono*"
                        maxLength="14"
                        minLength="9"
                        required
                        value={telefono}
                        onChange={e => setTelefono(e.target.value)}
                      />

                      <textarea
                        name="mensaje"
                        id="mensaje"
                        placeholder="¿Quieres darnos algo de info?"
                        cols="30"
                        rows="5"
                        value={mensaje}
                        onChange={e => setMensaje(e.target.value)}
                      ></textarea>

                      <div className="caja-checkbox">
                        <input
                          type="checkbox"
                          id="privacidad"
                          name="privacidad"
                          // defaultChecked={isChecked}
                          checked={isChecked}
                          onChange={() => setIsChecked(!isChecked)}
                        />
                        <label htmlFor="privacidad" className="label-check">
                          He leído y acepto la{" "}
                          <Link to="/politica-de-privacidad/">
                            política de privacidad
                          </Link>
                        </label>
                      </div>

                      <div
                        className={`mensaje-formulario
                                ${respuesta}                              
                            `}
                        dangerouslySetInnerHTML={{ __html: mensajeError }}
                      />

                      <IconContext.Provider value={{ color: "#1f1f1f" }}>
                        <input
                          type="submit"
                          value="REGISTRARME"
                          className="boton-enviar"
                          disabled={!isChecked}
                        />
                      </IconContext.Provider>
                      <p className="texto-recaptcha">
                        Este sitio está protegido por reCAPTCHA y se aplican la{" "}
                        <a href="https://policies.google.com/privacy">
                          política de privacidad
                        </a>{" "}
                        y los{" "}
                        <a href="https://policies.google.com/terms">
                          términos del servicio
                        </a>{" "}
                        de Google.
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}
